<template>
	<span class="error error-message error-icon" v-for="error in errors" :key="error">
		<i v-if="error.$property === inputsVmodel" class="fa-solid fa-circle-xmark"></i>
		<!-- <i class="fa-solid fa-circle-xmark"></i> -->
	</span>
</template>

<script>
export default {
	name: "Errors",
	props: ["errors", "inputsVmodel"],
}
</script>
<style scoped></style>
